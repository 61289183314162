<div class="flow-va-navigator-wrapper" *blockUI="'navigator-block-ui'; template: blockTemplate">
  <nz-tabset nzType="card" [(nzSelectedIndex)]="selectedTabIndex" [nzCanDeactivate]="canDeactivate">
    <nz-tab [nzTitle]="titleTemplateFlows">
      <ng-template #titleTemplateFlows>
        <div
          nz-dropdown
          [nzDropdownMenu]="menuFlows"
          [nzPlacement]="'bottomLeft'"
          class="tab-title-template-container d-flex justify-content-between"
          [style.pointerEvents]="isLoading ? 'none' : 'auto'"
        >
          <span
            class="ms-2 w-100"
            [attr.title]="
              selectedFlowTitleLong?.length >= titleShorteningLength ? selectedFlowTitleLong : null
            "
            (click)="navigateToFlow(flowVaNavigatorService.selectedProjectFlow?.id)"
          >
            {{ this.selectedFlowTitle }}
          </span>
          <span class="ms-2">
            <i class="fa fa-caret-down"></i>
          </span>
        </div>
        <nz-dropdown-menu #menuFlows="nzDropdownMenu" class="dropdown-inside-tab-title">
          <ul nz-menu class="tab-edit tab-drop-down-menu-list">
            <li
              *ngFor="let flowData of flowsData"
              nz-menu-item
              (click)="navigateToFlow(flowData.id)"
            >
              <div class="w-100 d-flex justify-content-between">
                <div>
                  <small
                    [attr.title]="
                      flowData.name.length >= titleShorteningLength ? flowData.name : null
                    "
                  >
                    {{ flowData.reducedName }}
                  </small>
                </div>
                <div *ngIf="flowData.flowType === subFlowTypeName" class="ms-2">
                  <small
                    (click)="renameSubFlow($event, flowData.id)"
                    *abpPermission="'Designer.Flows.Edit'"
                    title="{{ '::Rename' | abpLocalization }}"
                  >
                    <i class="fa fa-edit me-1 fa-xs text-secondary"></i>
                  </small>
                  <small
                    (click)="deleteSubFlow($event, flowData.id)"
                    *abpPermission="'Designer.Flows.Edit'"
                    title="{{ 'AbpUi::Delete' | abpLocalization }}"
                  >
                    <i class="fa fa-trash me-1 fa-xs text-secondary"></i>
                  </small>
                </div>
              </div>
            </li>
            <li nz-menu-item (click)="createSubFlow($event)">
              <div class="w-100 d-flex justify-content-between">
                <div>
                  <small>{{ createSubFlowLabel }}</small>
                </div>
              </div>
            </li>
          </ul>
        </nz-dropdown-menu>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="titleTemplateVirtualAgents">
      <ng-template #titleTemplateVirtualAgents>
        <div
          nz-dropdown
          [nzDropdownMenu]="menuVirtualAgents"
          [nzPlacement]="'bottomLeft'"
          class="tab-title-template-container d-flex justify-content-between"
          [style.pointerEvents]="isLoading ? 'none' : 'auto'"
        >
          <span
            class="ms-2 w-100"
            [attr.title]="
              selectedVirtualAgentTitleLong?.length >= titleShorteningLength
                ? selectedVirtualAgentTitleLong
                : null
            "
            (click)="
              navigateToVirtualAgent(
                flowVaNavigatorService.selectedVirtualAgent?.id,
                flowVaNavigatorService.selectedVirtualAgent?.name
              )
            "
          >
            {{ this.selectedVirtualAgentTitle }}
          </span>
          <span class="ms-2">
            <i class="fa fa-caret-down"></i>
          </span>
        </div>
        <nz-dropdown-menu
          #menuVirtualAgents="nzDropdownMenu"
          class="dropdown-inside-tab-title tab-drop-down-menu"
        >
          <ul nz-menu nzSelectable class="tab-edit tab-drop-down-menu-list">
            <li
              *ngFor="let virtualAgentData of virtualAgentsData"
              nz-menu-item
              [attr.title]="
                virtualAgentData.name.length >= titleShorteningLength ? virtualAgentData.name : null
              "
              (click)="navigateToVirtualAgent(virtualAgentData.id, virtualAgentData.name)"
            >
              <small> {{ virtualAgentData.reducedName }} </small>
            </li>
          </ul>
        </nz-dropdown-menu>
      </ng-template>
    </nz-tab>
  </nz-tabset>
</div>

<ng-template #blockTemplate>
  <div class="d-flex justify-content-center align-items-center block-ui-template">
    <div class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">...</span>
    </div>
  </div>
</ng-template>

<!-- EDIT SUB-FLOW MODAL -->
<abp-modal [(visible)]="isEditSubFlowModalOpen">
  <ng-template #abpHeader>
    <h3>{{ '::EditSubFlow' | abpLocalization }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <form [formGroup]="subFlowForm" (ngSubmit)="updateSubFlow()">
      <div class="mt-2 fade-in-top">
        <div class="form-group">
          <label for="current1">{{ '::Name' | abpLocalization }}:</label>
          <input type="text" class="form-control" formControlName="name" autofocus />
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button
      [disabled]="isSubFlowSavingInProgress"
      type="button"
      class="btn btn-outline-primary"
      (click)="closeSubFlowModal()"
    >
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>
    <abp-button
      iconClass="fa fa-check"
      [loading]="isSubFlowSavingInProgress"
      (click)="updateSubFlow()"
      [disabled]="subFlowForm?.invalid"
    >
      {{ 'AbpUi::Save' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>
<!-- END OF EDIT SUB-FLOW MODAL -->
