import { LocalizationService } from '@abp/ng.core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  constructor(private localizationService: LocalizationService) {}

  transform(value: number): string {
    const averageSessionDuration: { hour?: number; minute?: number; second?: number } = {
      hour: Math.floor(value / 3600),
      minute: Math.floor((value % 3600) / 60),
      second: Math.floor((value % 3600) % 60),
    };

    let duration = ``;

    if (averageSessionDuration.hour) {
      duration += `${averageSessionDuration.hour} ${this.localizationService.instant(
        '::HourAbbreviation'
      )}`;
    }

    if (averageSessionDuration.minute) {
      duration += averageSessionDuration.hour ? ` ` : ``;
      duration += `${averageSessionDuration.minute} ${this.localizationService.instant(
        '::MinuteAbbreviation'
      )}`;
    }

    if (averageSessionDuration.second) {
      duration += averageSessionDuration.minute ? ` ` : ``;
      duration += `${averageSessionDuration.second} ${this.localizationService.instant(
        '::SecondAbbreviation'
      )}`;
    }
    return duration;
  }
}
