export const PREFIX = 'OpenAi';
export const PROVIDER = PREFIX + '.Provider';
export const API_KEY = PREFIX + '.ApiKey';

export const MODEL = PREFIX + '.Model';

export const SERVICE_URL = PREFIX + '.ServiceUrl';
export const DEPLOYMENT_NAME = PREFIX + '.DeploymentName';
export const RESOURCE_NAME = PREFIX + '.ResourceName';

export const UTTERANCE_GENERATION_PHRASE =
  'Imagine that you are a chatbot and give me {0} utterances that user can say to you in {1}\ndescription: {2}\nintent name:{3}\n\nutterances:\n\n';
export const CONVERSATION_SUMMARIZATION_PHRASE =
  "###{0}\n###\nYour task is to generate a short summary of a call center dialog.\nInstructions:\nSummarize the main points of the above dialog, delimited by ###, in at most 45 words.\nYour answer must be in the conversation's language.";
export const TEXT_RESPONSE_GENERATION_PHRASE =
  'Imagine that you are a chatbot and give me 1 utterance that chatbot can say to user similar to "{0},{1},{2}" sentence(s). Answer in language of "{0},{1},{2}"';

export const MODEL_OPTIONS = [
  'gpt-3.5-turbo',
  'gpt-3.5-turbo-16k',
  'gpt-3.5-turbo-instruct',
  'gpt-4',
  'gpt-4-32k',
  'gpt-4-1106-preview',
];
