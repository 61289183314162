<div>
  <div class="row g-1" [formGroup]="editorForm" [style]="disabled ? 'pointer-events: none;' : ''">
    <div class="col">
      <cai-datetime-picker
        formControlName="start"
        [maxDate]="editorForm.get('end').value"
      ></cai-datetime-picker>
    </div>
    <div class="col" [hidden]="disableEndDate">
      <cai-datetime-picker
        formControlName="end"
        [minDate]="editorForm.get('start').value"
      ></cai-datetime-picker>
    </div>
    <div class="col" [hidden]="!disableEndDate">
      <input class="form-control form-control-sm" readonly [disabled]="true" [value]="emptyDate" />
    </div>
    <div *ngIf="config.useNoEndDate" class="col" style="margin-top: 8px; margin-bottom: 8px">
      <input
        #noEndDateCheckBox
        type="checkbox"
        id="noEndDate"
        [disabled]="disabled"
        (change)="onNoEndDateChange($event.currentTarget.checked)"
        [checked]="noEndDateChecked"
      />
      <label class="ps-1" for="noEndDate">
        {{ 'CA::NoEndDate' | abpLocalization }}
      </label>
    </div>
  </div>
  <div class="row" [hidden]="!config.useShortcuts" dir="ltr">
    <div class="col mt-3" [style]="disabled ? 'pointer-events: none;' : ''">
      <div class="btn-group-toggle">
        <label
          class="btn btn-link date-shortcut-link"
          [ngClass]="{ 'active-link': value.shortcut === predefinedDateRange.yesterday }"
        >
          <input
            (click)="onShortcutClick(predefinedDateRange.yesterday)"
            type="radio"
            name="options"
            id="option1"
            autocomplete="off"
            class="d-none"
          />
          {{ '::DateTimeRangePicker:Yesterday' | abpLocalization }}
        </label>
        <label
          class="btn btn-link date-shortcut-link"
          [ngClass]="{ 'active-link': value.shortcut === predefinedDateRange.today }"
        >
          <input
            (click)="onShortcutClick(predefinedDateRange.today)"
            type="radio"
            name="options"
            id="option2"
            autocomplete="off"
            class="d-none"
          />
          {{ '::DateTimeRangePicker:Today' | abpLocalization }}
        </label>
        <label
          class="btn btn-link date-shortcut-link"
          [ngClass]="{ 'active-link': value.shortcut === predefinedDateRange.lastSevenDays }"
        >
          <input
            (click)="onShortcutClick(predefinedDateRange.lastSevenDays)"
            type="radio"
            name="options"
            id="option3"
            autocomplete="off"
            class="d-none"
          />
          {{ '::DateTimeRangePicker:Last7Days' | abpLocalization }}
        </label>
        <label
          class="btn btn-link date-shortcut-link"
          [ngClass]="{ 'active-link': value.shortcut === predefinedDateRange.lastThirtyDays }"
        >
          <input
            (click)="onShortcutClick(predefinedDateRange.lastThirtyDays)"
            type="radio"
            name="options"
            id="option4"
            autocomplete="off"
            class="d-none"
          />
          {{ '::DateTimeRangePicker:Last30Days' | abpLocalization }}
        </label>
      </div>
    </div>
  </div>
</div>
