import { LocalizationService } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { HttpErrorResponse } from '@angular/common/http';
import { Injector } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
export const handleHttpErrors = (injector: Injector, httpError: HttpErrorResponse) => {
  if (httpError.status === 403 && httpError.error.error.code.includes('Raven')) {
    const options: Partial<Confirmation.Options> = {
      hideYesBtn: true,
      icon: 'fa fa-circle-exclamation',
    };
    const confirmationService = injector.get(ConfirmationService);
    const localizationService = injector.get(LocalizationService);
    confirmationService.warn(
      httpError.error.error.message,
      localizationService.instant('::Warning'),
      options
    );
    return;
  }
  return throwError(httpError);
};
