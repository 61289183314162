import { Component } from '@angular/core';
import { NotificationDto, NotificationModel, NotificationType } from '@sestek/notification';
import { ProjectExportedNotificationModel } from '../models/project-exported-notification-model';
import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { FileDownloadHelperService } from 'src/app/shared/services/file-download-helper.service';
import { TOASTER_LIFE } from 'src/app/shared/shared.consts';
import { DateFormatterPipe } from 'src/app/shared/pipes/date-formatter.pipe';
import { DateDisplayType } from 'src/app/shared/enums/date-display-type';
import { ProjectService } from 'src/app/projects/proxy/project.service';

@Component({
  selector: 'cai-project-exported-notification',
  templateUrl: './project-exported-notification.component.html',
  styleUrls: ['./project-exported-notification.component.scss'],
})
@NotificationType('ProjectExportedNotification')
export class ProjectExportedNotificationComponent {
  _data: NotificationDto;

  get data() {
    return this._data;
  }
  set data(data: NotificationDto) {
    this._data = data;
    this.notification = JSON.parse(data.payload);
  }

  notification: ProjectExportedNotificationModel;

  constructor(
    private projectService: ProjectService,
    private localizationService: LocalizationService,
    private toasterService: ToasterService,
    private fileDownloadHelperService: FileDownloadHelperService,
    private dateFormatterPipe: DateFormatterPipe
  ) {}

  showNotification(notification: NotificationModel) {
    this.notification = JSON.parse(notification.payload);

    const message = this.localizationService.instant('::Projects:ProjectExportIsReady');

    this.toasterService.success(message, '', { life: TOASTER_LIFE });
  }

  downloadFile() {
    let nowString = this.dateFormatterPipe.transform(
      new Date(),
      DateDisplayType.shortDateShortTime
    );

    nowString = nowString.replace(/[^a-zA-Z0-9]/g, '_');

    const fileName = `${this.notification.project}_${nowString}`;

    this.projectService.getProjectExport(this.notification.fileId).subscribe(
      (result) => {
        this.fileDownloadHelperService.handleDownloadFileResult(result, fileName);
      },
      (error) => {
        this.fileDownloadHelperService.handleDownloadFileError(error);
      }
    );
  }
}
