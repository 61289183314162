import { Directive, HostListener, Output, EventEmitter, ElementRef } from '@angular/core';

@Directive({
  selector: '[caiFileDragDrop]',
})
export class FileDragDropDirective {
  @Output() private filesDropped: EventEmitter<File[]> = new EventEmitter();
  isDragOverStyleAdded = false;
  dragging = 0;

  hostElement: ElementRef;

  constructor(element: ElementRef) {
    this.hostElement = element;
  }

  @HostListener('dragenter', ['$event']) public onDragEnter(e) {
    this.dragging++;

    e.preventDefault();
    e.stopPropagation();

    if (!this.isDragOverStyleAdded) {
      this.hostElement.nativeElement.classList.add('drag-drop-zone');
      this.isDragOverStyleAdded = true;
    }
  }

  @HostListener('dragover', ['$event']) public onDragOver(e) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.isDragOverStyleAdded) {
      this.hostElement.nativeElement.classList.add('drag-drop-zone');
      this.isDragOverStyleAdded = true;
    }
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(e) {
    this.dragging--;

    const dragDropContainerElement = e.srcElement.querySelector('.drag-drop-container');
    const isElementDragDropContainer = e.srcElement.classList.contains('drag-drop-container');

    if (this.dragging === 0 && (isElementDragDropContainer || dragDropContainerElement)) {
      this.resetStyles();
    }

    e.preventDefault();
    e.stopPropagation();
  }

  resetStyles() {
    this.hostElement.nativeElement.classList.remove('drag-drop-zone');
    this.isDragOverStyleAdded = false;
  }

  @HostListener('drop', ['$event']) public onDrop(e) {
    this.dragging = 0;
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;
    this.filesDropped.emit(Array.from(files));

    this.resetStyles();
  }
}
