<a href="javascript:;" (click)="downloadFile()">{{
  '::Projects:ClickHereToDownloadProjectExport' | abpLocalization
}}</a
>&nbsp;
<i
  ><u>{{ notification.project }}</u></i
>
(<i
  ><u>v{{ notification.version }}</u></i
>)
