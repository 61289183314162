<div>
  <h2>{{ '::OpenAiSettings' | abpLocalization }}</h2>
</div>
<br />
<hr />
<form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm()">
  <div class="mt-2 fade-in-top">
    <div class="form-group">
      <label for="provider" class="pt-3"
        >{{ '::OpenAiSettings:Provider' | abpLocalization }} *</label
      >
      <ng-select id="provider" formControlName="provider" (change)="setSelectedProvider()">
        <ng-option *ngFor="let providerOption of providerOptions" [value]="providerOption">
          {{ providerOption }}
        </ng-option>
      </ng-select>
    </div>

    <div class="form-group">
      <label for="apiKey">{{ '::OpenAiSettings:APIKey' | abpLocalization }} *</label>
      <input
        id="apiKey"
        formControlName="apiKey"
        class="form-control"
        type="password"
        autocomplete="new-password"
      />
    </div>

    <div class="form-group" *ngIf="isOpenAiSelected()">
      <label for="model">{{ '::OpenAiSettings:Model' | abpLocalization }} *</label>
      <ng-select id="model" formControlName="model">
        <ng-option *ngFor="let modelOption of modelOptions" [value]="modelOption">
          {{ modelOption }}
        </ng-option>
      </ng-select>
    </div>

    <div class="form-group" *ngIf="isAzureOpenAiSelected()">
      <label for="serviceUrl">{{ '::OpenAiSettings:ServiceUrl' | abpLocalization }} *</label>
      <input id="serviceUrl" formControlName="serviceUrl" class="form-control" type="text" />
    </div>

    <div class="form-group" *ngIf="isAzureOpenAiSelected()">
      <label for="deploymentName"
        >{{ '::OpenAiSettings:DeploymentName' | abpLocalization }} *</label
      >
      <input
        id="deploymentName"
        formControlName="deploymentName"
        class="form-control"
        type="text"
      />
    </div>

    <div class="form-group" *ngIf="isAzureOpenAiSelected()">
      <label for="resourceName">{{ '::OpenAiSettings:ResourceName' | abpLocalization }} *</label>
      <input id="resourceName" formControlName="resourceName" class="form-control" type="text" />
    </div>

    <span
      ><b
        ><p>{{ '::OpenAiSettings:Prompts' | abpLocalization }}</p></b
      ></span
    >

    <div class="form-group" *ngIf="isUtteranceGenerationFeatureEnabled">
      <label for="utteranceGeneration">{{
        '::OpenAiSettings:Prompts:UtteranceGeneration' | abpLocalization
      }}</label>
      <input
        id="utteranceGeneration"
        formControlName="utteranceGeneration"
        class="form-control"
        type="text"
        readonly
      />
    </div>
    <div class="form-group" *ngIf="isTextResponseGenerationFeatureEnabled">
      <label for="textResponseGeneration">{{
        '::OpenAiSettings:Prompts:TextResponseGeneration' | abpLocalization
      }}</label>
      <input
        id="textResponseGeneration"
        formControlName="textResponseGeneration"
        class="form-control"
        type="text"
        readonly
      />
    </div>

    <div class="form-group" *ngIf="isConversationSummarizationFeatureEnabled">
      <label for="conversationSummarization">{{
        '::OpenAiSettings:Prompts:ConversationSummarization' | abpLocalization
      }}</label>
      <input
        id="conversationSummarization"
        formControlName="conversationSummarization"
        class="form-control"
        type="text"
        readonly
      />
    </div>

    <div class="form-group" *ngIf="isLiveChatSummarizationFeatureEnabled">
      <label for="liveChatSummarization">{{
        '::OpenAiSettings:LiveChatSummarization' | abpLocalization
      }}</label>
      <input
        id="liveChatSummarization"
        formControlName="liveChatSummarization"
        class="form-control"
        type="text"
        readonly
      />
    </div>
  </div>
  <abp-button
    (click)="submitForm()"
    iconClass="fa fa-check"
    [loading]="isLoading"
    [disabled]="!isFormValid()"
    class="ms-1"
  >
    {{ 'AbpUi::Save' | abpLocalization }}
  </abp-button>
</form>
