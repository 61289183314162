import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { RavenMetaDataListDto } from 'src/app/integrations/proxy/integration.model';
import { ProjectDto } from 'src/app/projects/proxy/project.models';

@Injectable({ providedIn: 'root' })
export class FilterService {
  apiName = 'Default';

  constructor(private restService: RestService) {}

  getProjectOptions = () =>
    this.restService.request<void, ProjectDto[]>(
      {
        method: 'GET',
        url: '/api/app/filters/project-options',
      },
      { apiName: this.apiName }
    );

  getBackwardCompatibleChannelIntegrationTypes = () =>
    this.restService.request<void, RavenMetaDataListDto>(
      {
        method: 'GET',
        url: '/api/app/filters/channel-integration-name-list/backward-compatible',
      },
      { apiName: this.apiName }
    );
}
