<div>
  <h2>{{ '::CaIntegrationSettings' | abpLocalization }}</h2>
</div>
<br />
<hr />
<form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm()">
  <div class="mt-2 fade-in-top">
    <div class="form-group">
      <label for="isEnabled" class="pt-3">{{
        '::CaIntegrationSettings:IsEnabled' | abpLocalization
      }}</label>
      <nz-switch formControlName="isEnabled" class="ms-2"></nz-switch>
    </div>

    <div class="form-group">
      <label for="chatEndpointAddress">{{
        '::CaIntegrationSettings:ChatEndpoint' | abpLocalization
      }}</label>
      <input
        id="chatEndpointAddress"
        formControlName="chatEndpointAddress"
        class="form-control"
        type="text"
      />
    </div>

    <div class="form-group">
      <label for="tokenEndpointAddress">{{
        '::CaIntegrationSettings:TokenEndpoint' | abpLocalization
      }}</label>
      <input
        id="tokenEndpointAddress"
        formControlName="tokenEndpointAddress"
        class="form-control"
        type="text"
      />
    </div>

    <div class="form-group">
      <label for="clientId">{{ '::CaIntegrationSettings:ClientId' | abpLocalization }}</label>
      <input id="clientId" formControlName="clientId" class="form-control" type="text" />
    </div>

    <div class="form-group">
      <label for="clientSecret">{{
        '::CaIntegrationSettings:ClientSecret' | abpLocalization
      }}</label>
      <input
        id="clientSecret"
        type="password"
        autocomplete="new-password"
        formControlName="clientSecret"
        class="form-control"
      />
    </div>

    <div class="form-group">
      <label for="startDate">{{ '::CaIntegrationSettings:StartDate' | abpLocalization }}</label>
      <cai-datetime-picker id="startDate" formControlName="startDate"></cai-datetime-picker>
    </div>
  </div>
  <abp-button
    (click)="submitForm()"
    iconClass="fa fa-check"
    [loading]="isLoading"
    [disabled]="form.invalid"
    class="ms-1"
  >
    {{ 'AbpUi::Save' | abpLocalization }}
  </abp-button>
</form>
