import { Component } from '@angular/core';
import { NotificationDto, NotificationModel, NotificationType } from '@sestek/notification';
import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { TOASTER_LIFE } from 'src/app/shared/shared.consts';
import { GenerativeQuestionAnsweringTrainedNotificationModel } from 'src/app/projects/proxy/project.models';
import { ProjectService } from 'src/app/projects/proxy/project.service';

@Component({
  selector: 'cai-project-generative-qna-trained-notification',
  templateUrl: './project-generative-qna-trained-notification.component.html',
  styleUrls: ['./project-generative-qna-trained-notification.component.scss'],
})
@NotificationType('GenerativeQuestionAnsweringTrainedNotification')
export class ProjectGenerativeQnaTrainedNotificationComponent {
  _data: NotificationDto;

  get data() {
    return this._data;
  }
  set data(data: NotificationDto) {
    this._data = data;
    this.notification = JSON.parse(data.payload);
  }

  notification: GenerativeQuestionAnsweringTrainedNotificationModel;
  constructor(
    private localizationService: LocalizationService,
    private toasterService: ToasterService,
    private projectService: ProjectService
  ) {}

  showNotification(notification: NotificationModel) {
    this.notification = JSON.parse(notification.payload);

    this.projectService.generativeQuestionAnsweringTrainedSubject.next(this.notification);

    const message = this.localizationService.instant(
      '::GenerativeQuestionAnsweringTrainedSuccessfully',
      this.notification.name
    );

    this.toasterService.success(message, '', { life: TOASTER_LIFE });
  }
}
