/* eslint-disable @angular-eslint/no-output-on-prefix */
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { DateSelectionValueModel } from '../models/date-selection-value.model';
import { DateRangeSelectionConfigurationModel } from '../models/date-range-selection-configuration.model';
import { PredefinedDateRange } from '../enums/predefined-date-range';
@Component({
  selector: 'cai-date-range-selection-panel',
  templateUrl: './date-range-selection-panel.component.html',
  styleUrls: ['./date-range-selection-panel.component.scss'],
})
export class DateRangeSelectionPanelComponent {
  editorForm: UntypedFormGroup;
  private changedByShortcut = false;
  disableEndDate = false;
  noEndDateChecked = false;
  emptyDate: string;
  predefinedDateRange = PredefinedDateRange;

  _config: DateRangeSelectionConfigurationModel;

  @Output()
  public onChangeOut: EventEmitter<DateSelectionValueModel> = new EventEmitter();

  @Input()
  set config(value: DateRangeSelectionConfigurationModel) {
    this._config = value;

    if (this._config.startDate) {
      this.editorForm.get('start').patchValue(this._config.startDate);
    }

    if (this._config.endDate) {
      this.editorForm.get('end').patchValue(this._config.endDate);
    }
  }

  get config(): DateRangeSelectionConfigurationModel {
    return this._config;
  }

  @Input()
  disabled: boolean;

  _value: DateSelectionValueModel = {
    shortcut: null,
    start: new Date(),
    end: new Date(),
    noEndDate: false,
  };

  @Input()
  set value(value: DateSelectionValueModel) {
    if (!value) {
      return;
    }

    this._value = value;

    if (value.shortcut != null) {
      this.onShortcutClick(value.shortcut);
    } else {
      this.editorForm.patchValue(
        {
          start: value.start,
          end: value.end,
        },
        { emitEvent: value?.restoreExistingDate }
      );
    }
  }

  get value(): DateSelectionValueModel {
    return this._value;
  }

  constructor(protected fb: UntypedFormBuilder) {
    this.editorForm = this.fb.group({
      start: null,
      end: null,
    });

    this.editorForm.valueChanges.pipe(debounceTime(100)).subscribe((val) => {
      if (this.changedByShortcut) {
        this.changedByShortcut = false;
      } else {
        this._value.shortcut = null;
      }

      if (this._value.restoreExistingDate) {
        this._value.restoreExistingDate = false;
      }

      this._value.start = val.start;
      this._value.end = val.end;
      this.onChangeOut.emit(this.value);
    });
  }

  onNoEndDateChange(noEndDateChecked: boolean): void {
    this.noEndDateChecked = noEndDateChecked;
    this.disableEndDate = noEndDateChecked;
    if (noEndDateChecked) {
      this.value.noEndDate = true;
    } else {
      this.value.noEndDate = false;
    }
  }

  onShortcutClick(diff: number) {
    const start = new Date();
    const end = new Date();

    this.changedByShortcut = true;
    this._value.shortcut = diff;

    if (diff > 0) {
      end.setDate(end.getDate() + diff);
    } else {
      start.setDate(start.getDate() + diff);

      if (diff === -1) {
        end.setDate(end.getDate() + diff);
      }
    }
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);

    this._value.start = start;
    this._value.end = end;

    this.editorForm.patchValue(
      {
        start: start,
        end: end,
      },
      { emitEvent: true }
    );
  }
}
