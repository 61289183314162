import { LocalizationService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzTabsCanDeactivateFn } from 'ng-zorro-antd/tabs';
import {
  FlowVaNavigatorMenuItemModel,
  ProjectFlowSummaryDto,
  ProjectVirtualAgentSummaryDto,
} from 'src/app/projects/proxy/project.models';
import { ProjectService } from 'src/app/projects/proxy/project.service';
import { DesignerService } from '../designer/proxy/designer.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { filter, finalize, switchMap } from 'rxjs';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FlowType, RenameFlowCompletedEventArgs } from '../designer/proxy/designer.model';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { TOASTER_LIFE } from '../shared.consts';
import go from 'gojs';
import { FlowVaNavigatorService } from './flow-va-navigator.service';

@Component({
  selector: 'cai-flow-va-navigator',
  templateUrl: './flow-va-navigator.component.html',
  styleUrls: ['./flow-va-navigator.component.scss'],
})
export class FlowVaNavigatorComponent implements OnInit {
  virtualAgentsData: ProjectVirtualAgentSummaryDto[] = [];
  flowsData: ProjectFlowSummaryDto[] = [];
  selectedTabIndex = 0;
  currentUrlSegments = [];
  isInFlowPage = false;

  isEditSubFlowModalOpen = false;
  isSubFlowSavingInProgress = false;
  isLoading = false;
  subFlowForm: UntypedFormGroup;
  flowIdToEdit: string;

  selectedVirtualAgentTitle: string;
  selectedVirtualAgentTitleLong: string;
  selectedFlowTitle: string;
  selectedFlowTitleLong: string;

  currentFlowId: string;
  currentProjectVersionId: string;
  currentVirtualAgentId: string;

  createSubFlowLabel = '+ Subflow';
  selectedVirtualAgentTitlePrefix = 'VA:';
  selectedFlowTitlePrefix = 'Flow:';
  mainFlowName = 'Main';
  subFlowTypeName = 'SubFlow';
  virtualAgentDetailPageUrlSegmentsLength = 4;
  flowDesignerPageUrlSegmentsLength = 3;
  titleShorteningLength = 30;

  @BlockUI('navigator-block-ui') navigatorBlockUI: NgBlockUI;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private localizationService: LocalizationService,
    private designerService: DesignerService,
    private flowVaNavigatorService: FlowVaNavigatorService,
    public fb: UntypedFormBuilder,
    public toasterService: ToasterService,
    public confirmation: ConfirmationService
  ) {
    this.selectedVirtualAgentTitle = this.localizationService.instant('::Menu:VirtualAgents');
    this.selectedFlowTitle = this.localizationService.instant('::Flows');
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(() => {
      this.loadComponentData();
    });
  }

  loadComponentData() {
    this.currentUrlSegments = this.router.url.split('/').filter((element) => element);

    if (!this.currentUrlSegments) {
      return;
    }

    if (
      this.currentUrlSegments[0] === 'virtual-agents' &&
      this.currentUrlSegments.length >= this.virtualAgentDetailPageUrlSegmentsLength
    ) {
      this.currentVirtualAgentId = this.currentUrlSegments[1];
      this.getNavigatorDataByVirtualAgentId(this.currentVirtualAgentId);
      this.isInFlowPage = false;
    } else if (
      this.currentUrlSegments[0] === 'projects' &&
      this.currentUrlSegments[1] === 'scenario-design' &&
      this.currentUrlSegments.length === this.flowDesignerPageUrlSegmentsLength
    ) {
      const questionMarkIndex = this.currentUrlSegments[2].indexOf('?');
      if (questionMarkIndex > -1) {
        const queryParams = this.currentUrlSegments[2].substring(questionMarkIndex + 1);
        const queryParamsSegments = queryParams.split('=').filter((element) => element);

        if (queryParamsSegments?.length === 2) {
          this.currentFlowId = queryParamsSegments[1];
        }

        this.currentUrlSegments[2] = this.currentUrlSegments[2].substring(0, questionMarkIndex);
      }
      const projectVersionId = this.currentUrlSegments[2];
      this.isInFlowPage = true;
      this.getNavigatorDataByProjectVersionId(projectVersionId);
    }
  }

  getNavigatorDataByVirtualAgentId(virtualAgentId: string) {
    setTimeout(() => {
      this.isLoading = true;
    });

    if (!this.navigatorBlockUI.isActive) {
      this.navigatorBlockUI.start();
    }

    this.projectService
      .getFlowsAndVirtualAgentsByVirtualAgent(virtualAgentId)
      .subscribe((result) => {
        this.currentProjectVersionId = result.projectVersionId;
        this.virtualAgentsData = result.virtualAgentSummaries;
        this.flowsData = result.flowSummaries;

        setTimeout(() => {
          this.selectedTabIndex = 1;
        });

        const currentVirtualAgent = this.virtualAgentsData.find((v) => v.id === virtualAgentId);

        setTimeout(() => {
          this.setSelectedVirtualAgent(currentVirtualAgent);
          this.isLoading = false;
        });

        this.navigatorBlockUI.stop();
      });
  }

  getNavigatorDataByProjectVersionId(projectVersionId: string) {
    setTimeout(() => {
      this.isLoading = true;
    });

    if (!this.navigatorBlockUI.isActive) {
      this.navigatorBlockUI.start();
    }

    this.projectService
      .getFlowsAndVirtualAgentsByProjectVersion(projectVersionId)
      .subscribe((result) => {
        this.currentProjectVersionId = result.projectVersionId;
        this.virtualAgentsData = result.virtualAgentSummaries;
        this.flowsData = result.flowSummaries;

        setTimeout(() => {
          this.selectedTabIndex = 0;
        });

        let currentFlow = this.flowsData.find((f) => f.id === this.currentFlowId);

        if (this.currentFlowId && !currentFlow) {
          // This may be a deleted flow but still lives in query params on url, so replace it.
          const target = `projects/scenario-design/${this.currentProjectVersionId}`;
          this.router.navigate([target]);
        }

        setTimeout(() => {
          currentFlow = currentFlow ?? this.flowsData.find((f) => f.name === this.mainFlowName);
          this.setSelectedProjectFlow(currentFlow);
          this.isLoading = false;
        });

        this.navigatorBlockUI.stop();
      });
  }

  navigateToVirtualAgent(virtualAgentId: string, virtualAgentName: string) {
    if (virtualAgentId) {
      const target = `virtual-agents/${virtualAgentId}/${virtualAgentName}/intents`;
      this.router.navigate([target]);
    }
  }

  navigateToFlow(flowId: string) {
    if (this.isInFlowPage) {
      this.designerService.selectedFlowChangedSubject.next(flowId);
      const flowToNavigate = this.flowsData.find((f) => f.id === flowId);
      this.currentFlowId = flowId;

      setTimeout(() => {
        this.setSelectedProjectFlow(flowToNavigate);
      });
    } else {
      const target = `projects/scenario-design/${this.currentProjectVersionId}`;

      this.router.navigate([target], {
        queryParams: {
          flowId: flowId,
        },
      });
    }
  }

  // eslint-disable-next-line no-unused-vars
  canDeactivate: NzTabsCanDeactivateFn = (_fromIndex: number, _toIndex: number) => {
    return false;
  };

  renameSubFlow(event: any, flowId: string) {
    event.stopPropagation();

    this.flowIdToEdit = flowId;
    const flowName = this.flowsData.find((f) => f.id === flowId)?.name;
    this.buildSubFlowForm(flowName);
    this.isEditSubFlowModalOpen = true;
  }

  buildSubFlowForm(flowName?: string) {
    const subFlowCount = this.flowsData.filter(
      (flow) => flow.flowType === this.subFlowTypeName
    ).length;

    this.subFlowForm = this.fb.group({
      name: new UntypedFormControl(flowName || this.generateSubFlowName(subFlowCount + 1), [
        Validators.required,
      ]),
    });
  }

  generateSubFlowName(currentNumber: number) {
    const generatedName = this.subFlowTypeName + ' ' + currentNumber;
    if (this.flowsData.filter((flow) => flow.name === generatedName).length > 0) {
      return this.generateSubFlowName(currentNumber + 1);
    } else {
      return generatedName;
    }
  }

  closeSubFlowModal() {
    this.isEditSubFlowModalOpen = false;
  }

  updateSubFlow() {
    if (this.subFlowForm.invalid || this.isSubFlowSavingInProgress) return;

    this.isSubFlowSavingInProgress = true;

    this.designerService
      .updateSubFlowName(this.flowIdToEdit, {
        name: this.subFlowForm.get('name').value,
      })
      .pipe(finalize(() => (this.isSubFlowSavingInProgress = false)))
      .subscribe({
        next: (res: any) => {
          this.toasterService.success('LeptonThemeManagement::SuccessfullySaved', '', {
            life: TOASTER_LIFE,
          });

          this.isEditSubFlowModalOpen = false;

          this.refreshNavigatorData();

          if (this.isInFlowPage) {
            const renameFlowCompletedEventArgs: RenameFlowCompletedEventArgs = {
              flowId: res.id,
              newName: res.name,
            };

            this.designerService.renameFlowCompletedSubject.next(renameFlowCompletedEventArgs);
          }
        },
      });
  }

  deleteSubFlow(event: any, flowId: string) {
    event.stopPropagation();

    const name = this.flowsData.find((f) => f.id === flowId)?.name;

    const deleteFlowIndex = this.flowsData.findIndex((f) => f.id === flowId);
    const currentFlowIndex = this.flowsData.findIndex((f) => f.id === this.currentFlowId);

    this.confirmation
      .warn('::DeleteConfirmationMessageWithName', '::DeleteRecordConfirmationMessage', {
        messageLocalizationParams: [name],
      })
      .pipe(
        filter((status) => status === Confirmation.Status.confirm),
        switchMap(() => this.designerService.deleteSubFlows(flowId))
      )
      .subscribe(() => {
        if (currentFlowIndex === deleteFlowIndex) {
          if (currentFlowIndex === this.flowsData.length - 1) {
            this.currentFlowId = this.flowsData[currentFlowIndex - 1].id;
          } else {
            this.currentFlowId = this.flowsData[currentFlowIndex + 1].id;
          }
        }

        this.refreshNavigatorData();

        if (this.isInFlowPage) {
          this.designerService.deleteFlowCompletedSubject.next(flowId);
        }
      });
  }

  createSubFlow(event: any) {
    event.stopPropagation();

    this.buildSubFlowForm();

    this.isSubFlowSavingInProgress = true;

    const create = {
      ...this.subFlowForm.value,
      flowType: FlowType.subFlow,
      projectVersionId: this.currentProjectVersionId,
      json: new go.GraphLinksModel([], []),
    };

    this.designerService
      .createSubFlows(create)
      .pipe(finalize(() => (this.isSubFlowSavingInProgress = false)))
      .subscribe({
        next: (flow: any) => {
          flow.json = JSON.parse(flow.json);
          this.toasterService.success('LeptonThemeManagement::SuccessfullySaved', '', {
            life: TOASTER_LIFE,
          });
          this.currentFlowId = flow.id;

          this.refreshNavigatorData();

          if (this.isInFlowPage) {
            this.designerService.createFlowCompletedSubject.next(flow);
          }
        },
      });
  }

  private refreshNavigatorData() {
    if (this.isInFlowPage) {
      this.getNavigatorDataByProjectVersionId(this.currentProjectVersionId);
    } else {
      this.getNavigatorDataByVirtualAgentId(this.currentVirtualAgentId);
    }
  }

  private setSelectedVirtualAgent(selectedVirtualAgent: FlowVaNavigatorMenuItemModel) {
    this.flowVaNavigatorService.selectedVirtualAgent = selectedVirtualAgent;

    if (this.flowVaNavigatorService.currentProjectVersionId !== this.currentProjectVersionId) {
      this.flowVaNavigatorService.selectedProjectFlow = null;
    }

    this.updateSelectedVirtualAgentAndFlowTitle();

    this.flowVaNavigatorService.currentProjectVersionId = this.currentProjectVersionId;
  }

  private setSelectedProjectFlow(selectedProjectFlow: ProjectFlowSummaryDto) {
    this.flowVaNavigatorService.selectedProjectFlow = selectedProjectFlow;

    if (this.flowVaNavigatorService.currentProjectVersionId !== this.currentProjectVersionId) {
      this.flowVaNavigatorService.selectedVirtualAgent = null;
    }

    this.updateSelectedVirtualAgentAndFlowTitle();

    this.flowVaNavigatorService.currentProjectVersionId = this.currentProjectVersionId;
  }

  private updateSelectedVirtualAgentAndFlowTitle() {
    const selectedVirtualAgent = this.flowVaNavigatorService.selectedVirtualAgent;
    if (selectedVirtualAgent) {
      this.selectedVirtualAgentTitle = `${this.selectedVirtualAgentTitlePrefix} ${selectedVirtualAgent?.reducedName}`;
      this.selectedVirtualAgentTitleLong = selectedVirtualAgent?.name;
    } else {
      this.selectedVirtualAgentTitle = this.localizationService.instant('::Menu:VirtualAgents');
      this.selectedVirtualAgentTitleLong = '';
    }

    const selectedProjectFlow = this.flowVaNavigatorService.selectedProjectFlow;

    if (selectedProjectFlow) {
      this.selectedFlowTitle = `${this.selectedFlowTitlePrefix} ${selectedProjectFlow?.reducedName}`;
      this.selectedFlowTitleLong = selectedProjectFlow?.name;
    } else {
      this.selectedFlowTitle = this.localizationService.instant('::Flows');
      this.selectedFlowTitleLong = '';
    }
  }
}
